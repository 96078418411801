export interface INavigationRoute {
  name: string
  displayName: string
  meta: { icon: string }
  children?: INavigationRoute[]
}

export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    {
      name: 'merchants',
      displayName: 'menu.merchants',
      meta: {
        icon: 'store',
      },
    },
    // {
    //   name: 'staffs',
    //   displayName: 'menu.staffs',
    //   meta: {
    //     icon: 'group',
    //   },
    // },
    {
      name: 'users',
      displayName: 'menu.users',
      meta: {
        icon: 'person',
      },
    },
    {
      name: 'categorys',
      displayName: 'menu.categorys',
      meta: {
        icon: 'library_books',
      },
    },
    {
      name: 'productCategorys',
      displayName: 'menu.productCategorys',
      meta: {
        icon: 'library_books',
      },
    },
    {
      name: 'promotions',
      displayName: 'menu.promotions',
      meta: {
        icon: 'confirmation_number',
      },
    },
    {
      name: 'products',
      displayName: 'menu.products',
      meta: {
        icon: 'inventory_2',
      },
    },

    {
      name: 'vouchers',
      displayName: 'menu.vouchers',
      meta: {
        icon: 'redeem',
      },
    },
    {
      name: 'orders',
      displayName: 'menu.orders',
      meta: {
        icon: 'attach_money',
      },
    },
    {
      name: 'pointRedeems',
      displayName: 'menu.pointRedeems',
      meta: {
        icon: 'paid',
      },
    },
    {
      name: 'withdraws',
      displayName: 'menu.withdraws',
      meta: {
        icon: 'payments',
      },
    },
    // {
    //   name: 'randomLists',
    //   displayName: 'menu.randomLists',
    //   meta: {
    //     icon: 'reorder',
    //   },
    // },
    {
      name: 'withdrawPlatformSettings',
      displayName: 'menu.withdrawPlatformSettings',
      meta: {
        icon: 'reorder',
      },
    },
  ] as INavigationRoute[],
}
