import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { GoogleAuthProvider, getAuth } from 'firebase/auth'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'

const firebaseConfig = {
  apiKey: 'AIzaSyB9EO5y0bjNl1aYyRXfGlLdSgnId5jq5NM',
  authDomain: 'blazz-referral.firebaseapp.com',
  projectId: 'blazz-referral',
  storageBucket: 'blazz-referral.appspot.com',
  messagingSenderId: '729327843279',
  appId: '1:729327843279:web:361eb70e43330141c3c4ba',
  measurementId: 'G-5LHTK3DPRR',
}

// const firebaseConfig = {
//   apiKey: 'AIzaSyBQwpeM-ESxd0ETUXufpwwBDJZzbbDdhk0',
//   authDomain: 'blazz-referral-v2.firebaseapp.com',
//   projectId: 'blazz-referral-v2',
//   storageBucket: 'blazz-referral-v2.firebasestorage.app',
//   messagingSenderId: '175653549237',
//   appId: '1:175653549237:web:ae4e4bebe99dbd5509bafc',
// }

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const messaging = getMessaging(app)

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app)

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app)
const provider = new GoogleAuthProvider()

// Convert Firestore Images
const firestoreConvertToImage = (link: any) => {
  const storeUrl = encodeURIComponent(link)
  return `https://firebasestorage.googleapis.com/v0/b/${firebaseConfig.storageBucket}/o/${storeUrl}?alt=media`
}

export { db, app, auth, provider, messaging, firestoreConvertToImage }
